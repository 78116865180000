<template>
  <div class="my-container">
    <!-- 我的信息 -->
    <div class="my-content">
      <div style="display: flex;flex-direction: row;justify-content: center;">
        <div :style="'width: 3.625rem;height: 3.625rem;margin: 4.9375rem 0.8125rem 0 1.125rem;background-image: url('+ author.avatar +');background-repeat: no-repeat;background-attachment: scroll;background-position: 30% 50%;background-size: 100% auto;background-color: transparent;border-radius: 50%;position: relative;'">
          <div
            @click="goCertification"
            class="row-center"
            style="width: 3.125rem;height: .9375rem;padding: 1px 0;border-radius: .5rem;position: absolute;bottom: -0.5rem;left: 0.25rem;font-size: .75rem;font-weight: 400;color: #FFFFFF;line-height: .9375rem;"
            :style="!isAuth ? 'background: #4C75FD;': 'background: #F55F5F;'"
          >{{ !isAuth ? '已实名' : '未实名' }}</div>
        </div>

      </div>
      <div
        style="width: calc(100vw - 5.5rem);height: 80%;display: flex;flex-direction: row;justify-content: space-between;"
        @click="goMyDetail()"
      >
        <div>
          <p class="collection-man-name">{{ author.nickname }}</p>
          <p class="collection-man-id">ID:{{ author.uid }}</p>
        </div>
        <img
          class="next-img"
          src="../assets/icon/next.png"
        />
      </div>

    </div>
    <div class="order-content-order order-content row-around">
      <div
        @click="selectOrderType()"
        class="col-center"
      >
        <img
          class="order-donation-compositon-img"
          style="width:auto;height: 2rem;"
          src="../assets/icon/my/order.png"
        />
        <p class="collection-man-id">订单</p>
      </div>
      <div
        @click="toComposite()"
        class="col-center"
      >
        <img
          class="order-donation-compositon-img"
          style="width:auto;height: 2rem;"
          src="../assets/icon/my/composite.png"
        />
        <p class="collection-man-id">合成</p>
      </div>
      <div
        @click="donationCollection()"
        class="col-center"
      >
        <img
          class="order-donation-compositon-img"
          style="width:auto;height: 2rem;"
          src="../assets/icon/my/donation.png"
        />
        <p class="collection-man-id">转赠</p>
      </div>
      <div
        @click="conversionCollection()"
        class="col-center"
      >
        <img
          class="order-donation-compositon-img"
          style="width:auto;height: 2rem;"
          src="../assets/icon/my/conversion.png"
        />
        <p class="collection-man-id">兑换</p>
      </div>
      <div
        @click="toCoupon()"
        class="col-center"
      >
        <img
          class="order-donation-compositon-img"
          style="width:auto;height: 2rem;"
          src="../assets/icon/coupon/coupon-icon.png"
        />
        <p class="collection-man-id">优惠券</p>
      </div>
    </div>
    <!-- 积分 -->
    <div
      @click="$router.push('/integral')"
      class="order-content-nomal order-content row-between"
    >
      <div class="row-center">
        <img
          class="order-img"
          style="width:1.25rem;height: auto;"
          src="../assets/icon/my/integral.png"
        />
        <p class="collection-man-id">积分</p>
      </div>
      <div class="row-center">
        <p style="margin-right:10px;">{{author.integral}}</p>
        <img
          class="next-img"
          src="../assets/icon/next.png"
        />
      </div>

    </div>
    <!-- 设置 -->
    <div
      @click="$router.push('/set')"
      class="order-content-nomal order-content row-between"
    >
      <div class="row-center">
        <img
          class="order-img"
          style="width:1.25rem;height: auto;"
          src="../assets/icon/my/set.png"
        />
        <p class="collection-man-id">设置</p>
      </div>
      <img
        class="next-img"
        src="../assets/icon/next.png"
      />
    </div>
    <!-- 邀请好友 -->
    <div
      @click="inviteFriends()"
      class="order-content order-content-nomal row-between"
    >
      <div class="row-center">
        <img
          class="order-img"
          style="width:1.25rem;height: auto;"
          src="../assets/icon/my/share.png"
        />
        <p class="collection-man-id">邀请好友</p>
      </div>
      <img
        class="next-img"
        src="../assets/icon/next.png"
      />
    </div>
    <!-- 联系我们 -->
    <div
      @click="$router.push('/aboutUs')"
      class="order-content order-content-nomal row-between"
    >
      <div class="row-center">
        <img
          class="order-img"
          style="width:1.25rem;height: auto;"
          src="../assets/icon/my/contact-us.png"
        />
        <p class="collection-man-id">联系我们</p>
      </div>
      <img
        class="next-img"
        src="../assets/icon/next.png"
      />
    </div>
    <!-- 关于 -->
    <div
      @click="$router.push('/about')"
      class="order-content order-content-nomal row-between"
    >
      <div class="row-center">
        <img
          class="order-img"
          style="width:1.25rem;height: auto;"
          src="../assets/icon/my/about.png"
        />
        <p class="collection-man-id">关于</p>
      </div>
      <img
        class="next-img"
        src="../assets/icon/next.png"
      />
    </div>
    <el-dialog
      title="账户未登录"
      :visible.sync="loginVisible"
      @confirm="$router.push('/login')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="loginVisible = false">取 消</el-button>
        <div
          @click="$router.push('/login')"
          class="confirm-button row-center"
        >去登录</div>
      </span>
    </el-dialog>
    <!-- 转赠tip -->
    <el-dialog
      :visible.sync="donationErrorVisible"
      @confirm="donationErrorVisible = false"
      class="donation-dialog"
      center
    >
      <p class="donation-error-title">{{ donationErrorTitle }}</p>
      <p class="donation-error-tip">{{ donationErrorTip }}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="donationErrorVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="$router.push('/certification')"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import Vue from 'vue'
import { mapGetters } from "vuex";
import Cookies from 'js-cookie'
import { List } from 'vant';
Vue.use(List);
import config from '../config'
import { Button,Dialog } from 'element-ui';
Vue.use(Button)
Vue.use(Dialog)
export default {
  data () {
    return {
      author: {
        icon: '',
        name: '',
        id: '',
      },
      integral: '1200',
      authVisible: false,
      donationErrorVisible: false,
      donationErrorTitle: '',
      donationErrorTip: '',
      loginVisible: false,
      loading: false,
      finished: false,
      orderTypeShow: false,
      goodsParam: {
        page: 0,
        limit: 12,
      },
      mediaType: config.mediaType
    }
  },
  computed: {
    ...mapGetters(["isAuth"]),
  },
  mounted () {
    localStorage.removeItem('uidOrPhopneType')
    if (!Cookies.get('Login')) {
      this.loginVisible = true
    } else {
      this.$store.commit('SHOW_APPLOADING')
      this.getMyInfo() // 获取个人资料
    }

  },
  methods: {
    // 跳转合成
    toComposite () {
      this.$router.push("/compositeList")
    },
    // 选择订单类型
    selectOrderType () {
      this.$router.push("/orderSelect")
    },
    // 跳转优惠券
    toCoupon () {
      this.$router.push("/coupon")
    },
    onCancel () {
    },
    goCertification () {
      if (this.isAuth) {
        this.$router.push("/certification")
      }
    },
    // 兑换实物
    conversionCollection () {
      // 是否实名认证
      if (this.author.hasRealname) {
        localStorage.removeItem('conversionCollection')
        this.$router.push("/conversion")
      } else {
        this.authVisible = true
      }
    },
    // 转赠
    donationCollection () {
      if (Cookies.get('Device') == 'pico') {
        this.$toast({
          message: '请移步手机端进行转赠',
          icon: require('../assets/icon/toast-error.png'),
        });
      } else {
        this.giftChooseEnd(this.$route.query.id)
      }
    },
    // 获取转赠藏品信息
    giftChooseEnd () {
      api
        .post('gift/choose/list/check')
        .then(result => {
          if (result.data.success) {
            localStorage.removeItem('selectUniqueList')
            localStorage.removeItem('selectIdList')
            this.$store.commit('SET_SELECTCOLLECTIONTAB',result.data.data == 0 || result.data.data == 2 ? '1' : '2')
            localStorage.removeItem('selectUniqueList')
            localStorage.setItem('selectCollectionTab',result.data.data == 0 || result.data.data == 2 ? '0' : '1')
            this.$router.push('/donation')
          } else if (result.data.status == '6003') {
            this.authVisible = true
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.show = false
            this.donationErrorVisible = true
            this.donationErrorTitle = result.data.status != '10007' && result.data.status != '10008' ? result.data.msg : result.data.msg.split('\n')[0]
            this.donationErrorTip = result.data.status == '10007' || result.data.status == '10008' ? result.data.msg.split('\n')[1] : ''
          }
        })
    },
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.author = result.data.data
            if (result.data.data.hasRealname) {
              this.$store.commit('HIDE_ISAUTH')
            } else {
              this.$store.commit('SHOW_ISAUTH')
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    inviteFriends () {
      this.$router.push('/invitationFriends')
    },
    // 个人详情页面入口
    goMyDetail () {
      this.$store.commit('SHOW_APPLOADING')
      this.$router.push('/myDetail')
    },
  },
}
</script>

<style lang="less" scoped>
.my-container {
  margin-bottom: 3.125rem;
  height: calc(100vh - 3.125rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  background: #1c1c1c;
  .my-content {
    width: 100vw;
    height: 12.75rem;
    background-image: url('../assets/icon/my/my-bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 100;
    .collection-man-name {
      font-size: 1.125rem;
      font-family: lantingheiweight;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.5625rem;
      margin-top: 5.5rem;
    }

    .collection-man-id {
      font-size: 0.75rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.0625rem;
      word-wrap: break-word;
    }
    .next-img {
      width: 0.5rem;
      height: 0.8125rem;
      margin-right: 25px;
      margin-top: 6.625rem;
    }
  }
  .order-content-order {
    margin-top: -1.875rem;
    width: 90%;
    margin-left: 5%;
    height: 86px;
    background: #262728;
    border-radius: 8px;
    cursor: pointer;
    z-index: 101;
  }
  .order-content-nomal {
    width: 90%;
    margin-left: 5%;
    height: 50px;
    background: #262728;
    border-radius: 8px;
    margin-top: 0.75rem;
    cursor: pointer;
    z-index: 101;
  }
  .order-content {
    .order-img {
      margin: 0 0.75rem;
    }
    .order-donation-compositon-img {
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      font-family: lantinghei;
      font-weight: normal;
      color: #f0f0f0;
      line-height: 19px;
    }
    .next-img {
      width: 0.5rem;
      height: 0.8125rem;
      margin-right: 1rem;
    }
  }
}
</style>